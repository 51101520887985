<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-5 col-12">Discontinued Schemes</div>
        <div class="
                        col-lg-7 col-12
                        text-end
                        d-flex
                        justify-content-end
                        custom-flex-cloumn-mob
                      ">
        </div>
    </div>
    <div class="custom-ultima-datatable">
        <DataTable :value="schemeList" :scrollable="true" scrollHeight="flex" columnResizeMode="fit" :paginator="true" :lazy="true"
            :rowHover="true" :totalRecords="totalRecords" :rows="30" dataKey="aj1" @page="changePage($event)"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            :loading="loading">
            <template #empty>No Records found.</template>
            <template #loading>Loading schemes data. Please wait...</template>
            <Column field="Name" header="Scheme Name" headerStyle="width: 55%" bodyStyle="width: 55%">
                <template #body="{ data }">
                    <div>{{ data.schemelabel ? data.schemelabel : "N/A" }}</div>
                </template>
            </Column>
            <Column field="Name" header="Launch Date" headerStyle="width: 15%" bodyStyle="width: 15%">
                <template #body="{ data }">
                    <div>{{ data.schemedate ? data.schemedate : "N/A" }}</div>
                </template>
            </Column>
            <Column field="Name" header="Closed Date" headerStyle="width: 15%" bodyStyle="width: 15%">
                <template #body="{ data }">
                    <div>{{ data.schemedateclosed ? data.schemedateclosed : "N/A" }}</div>
                </template>
            </Column>
            <Column field="Name" header="Beneficiaries Count" headerStyle="width: 15%" bodyStyle="width: 15%">
                <template #body="{ data }">
                    <div>{{ data.benifitcount ? data.benifitcount : "N/A" }}</div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
    <script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
    data() {
        return {
            v$: useValidate(),
            schemeList: [
                {
                    id: 0,
                    schemelabel: "Pension Scheme",
                    schemedate: "01/04/1924",
                    schemedateclosed: "01/04/2003",
                    benifitcount: "25,90,000",
                    disabledflag: 0,
                },
                {
                    id: 4,
                    schemelabel: "National Pension System",
                    schemedate: "12/01/1999",
                    schemedateclosed: "31/05/2004",
                    benifitcount: "22,95,529",
                    disabledflag: 0,
                },
            ],
            totalRecords: 5,
            loading: false,
            addschememodalsflag: false,
            schemename: "",
            modalloader: false,
            schemestatusflag: 0,
            disabledflag: 0,
        };
    },
    validations() {
        return {
            schemename: {
                required: helpers.withMessage("Please enter scheme name", required),
            },
        };
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },

    mounted() {
        // this.loading = true;
        // this.getcampaigntemplates();
    },

    methods: {
        // getcampaigntemplates(item) {
        //     this.ApiService.getcampaigntemplates(item).then((data) => {
        //         if (data.success === true) {
        //             this.schemeList = data.records;
        //             this.totalRecords = data.totalcount;
        //             this.loading = false;
        //         } else {
        //             this.loading = false;
        //             this.schemeList = null;
        //             this.totalRecords = 0;
        //         }
        //     });
        // },
        changePage(event) {
            this.page_no = event.page;
            // this.getcampaigntemplates({ page_no: this.page_no });
        },
        addschemeModalOpen() {
            this.addschememodalsflag = true;
        },
        addschemeModalClose() {
            this.addschememodalsflag = false;
        },
    },
};
</script>
<style scoped>
.modal-submit-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
}

.modal-submit-btn:focus {
    box-shadow: none;
}
.scheme-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.scheme-badge.status-open {
  background: #c8e6c9;
  color: #256029;
}

.scheme-badge.status-closed {
  background: #ffcdd2;
  color: #c63737;
}
</style>